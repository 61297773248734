/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useContext } from 'react';
import moment from 'moment';
import * as Ink from '@carta/ink';
import { graphql } from 'gatsby';
import { useAnalyticsV2 } from '@carta/fep-analytics';

import Layout from '../components/Layout';
import Header, { FIGMA_URL } from '../components/Header/Header';
import StyledLink from '../components/Common/StyledLink';

import DocsContext from '../contexts/DocsContext';
import pj from '../../package.json';

const getWelcomeString = userName => {
  const hour = moment().hour();
  if (hour > 16) {
    return `Good evening, ${userName !== '' ? userName : 'Carta'} ✨`;
  }

  if (hour > 11) {
    return `Good afternoon, ${userName !== '' ? userName : 'Carta'} ✨`;
  }

  return `Top of the morning, ${userName !== '' ? userName : 'Carta'} ✨`;
};

const HomeCard = ({ title, tagline, url, linkLabel, externalLink = false, onClick }) => {
  return (
    <Ink.Tile height="full">
      <Ink.Box>
        <Ink.Heading variant="heading-3">{title}</Ink.Heading>
        <Ink.Text>{tagline}</Ink.Text>
        {externalLink ? (
          <Ink.Anchor href={url} preset="new-tab" target="_blank" onClick={onClick}>
            {linkLabel}
          </Ink.Anchor>
        ) : (
          <StyledLink to={url} onClick={onClick}>
            {linkLabel}
          </StyledLink>
        )}
      </Ink.Box>
    </Ink.Tile>
  );
};

const Home = ({ data: { latestPatterns } }) => {
  const recentRelease = pj.dependencies['@carta/ink'];
  const { trackAction } = useAnalyticsV2();

  const { userName, updateAvailable } = useContext(DocsContext);

  return (
    <Layout pageTitle="ink">
      {updateAvailable && (
        <Ink.Box bottom="48px">
          <Ink.Banner
            trim
            text={`ink version ${recentRelease} has been released!`}
            primaryAction={{ text: 'Reload to update', onClick: () => window.location.reload() }}
          />
        </Ink.Box>
      )}
      <Ink.Box bottom="32px">
        <Header title={getWelcomeString(userName)} />
      </Ink.Box>
      <Ink.Row>
        <Ink.Column mobile="12-of-12" tabletLandscape="4-of-12">
          <HomeCard
            title="Getting Started"
            tagline="ink is Carta’s Design System. It allows Carta to build product UI with great user experience at scale. Click here to know more how you can start working with ink."
            url="/getting-started"
            linkLabel="View more"
            onClick={() => trackAction({ elementId: 'home-link-getting-started', type: 'click' })}
          />
        </Ink.Column>
        <Ink.Column mobile="12-of-12" tabletLandscape="4-of-12">
          <HomeCard
            title="Accessibility"
            tagline="A collection of articles and guides to help you learn more about accessibility. What you should have in mind when designing and coding for accessibility? What are some of the accessibility terms and what they mean? How do you do a full accessibility audit of your product?"
            url="/accessibility"
            linkLabel="View more"
            onClick={() => trackAction({ elementId: 'home-link-accessibility', type: 'click' })}
          />
        </Ink.Column>
      </Ink.Row>
      <Ink.Row>
        <Ink.Column mobile="12-of-12" tabletLandscape="4-of-12">
          <HomeCard
            title="Guides"
            tagline="Browse our collection of guides that will help you work better using ink. Here you will discover how to use layout components to define page grid and control spacing or use our color pallete for example."
            url="/guides"
            linkLabel="View more"
            onClick={() => trackAction({ elementId: 'home-link-guides', type: 'click' })}
          />
        </Ink.Column>
        <Ink.Column mobile="12-of-12" tabletLandscape="4-of-12">
          <HomeCard
            title="Playroom"
            tagline="Designers and DTs at Carta uses Playroom to create faster prototypes to share their ideas while using real ink components."
            url="https://ink.carta.com/playroom/#"
            linkLabel="Go to Playroom"
            onClick={() => trackAction({ elementId: 'home-link-playroom', type: 'click' })}
            externalLink
          />
        </Ink.Column>
      </Ink.Row>
      <Ink.Row>
        <Ink.Column mobile="12-of-12" tabletLandscape="4-of-12">
          <HomeCard
            title="Primer"
            tagline="Browse our collection of guides that will help you work better using ink. Here you will discover how to use layout components to define page grid and control spacing or use our color pallete for example."
            url="https://labs.ink.carta.com/primer/"
            linkLabel="Go to Primer"
            onClick={() => trackAction({ elementId: 'home-link-primer', type: 'click' })}
            externalLink
          />
        </Ink.Column>
        <Ink.Column mobile="12-of-12" tabletLandscape="4-of-12">
          <HomeCard
            title="Figma"
            tagline="ink provides code and no-code design tooling for working with ink. The ink on Figma project is building the next evolution in ink’s no-code design support. You need to be connected to Carta’s Figma organization in order to use it."
            url={FIGMA_URL}
            linkLabel="Go to Figma library"
            onClick={() => trackAction({ elementId: 'home-link-figma', type: 'click' })}
            externalLink
          />
        </Ink.Column>
      </Ink.Row>
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query RecentReleaseQuery {
    latestPatterns: allFile(
      sort: { fields: modifiedTime, order: DESC }
      filter: { extension: { eq: "mdx" }, childMdx: { fileAbsolutePath: { regex: "/patterns/" } } }
      limit: 5
    ) {
      nodes {
        childMdx {
          frontmatter {
            title
            subtitle
          }
          slug
        }
      }
    }
  }
`;
